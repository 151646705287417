import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">



<path d="M5580 10271 c-316 -144 -507 -274 -724 -494 -203 -207 -363 -447
-484 -727 -46 -106 -121 -375 -142 -510 -27 -171 -24 -474 6 -720 8 -61 59
-255 96 -365 54 -159 185 -412 283 -548 68 -94 87 -117 94 -112 5 2 8 -2 7 -9
-1 -20 136 -169 243 -263 115 -103 128 -113 186 -153 28 -18 52 -36 55 -40 5
-6 39 -26 120 -73 80 -46 182 -97 251 -127 41 -17 83 -35 94 -40 32 -14 174
-57 245 -75 340 -87 788 -85 1115 5 139 38 391 130 405 149 3 4 24 15 47 25
23 10 40 22 38 27 -3 4 0 11 6 15 8 4 9 3 5 -4 -13 -22 8 -12 99 44 161 99
295 204 415 324 59 59 160 173 173 196 4 8 20 26 34 42 45 48 213 314 213 338
0 5 -17 -16 -37 -46 -114 -170 -284 -348 -473 -493 -58 -45 -90 -67 -95 -67
-3 0 -23 -13 -43 -29 -95 -76 -414 -216 -602 -265 -234 -60 -528 -87 -738 -67
-376 36 -706 150 -1015 350 -54 35 -97 67 -97 71 0 5 -6 10 -14 12 -39 9 -291
249 -379 362 -168 215 -285 422 -360 641 -46 132 -104 397 -113 510 -33 441
37 818 221 1187 49 98 137 246 155 258 3 3 9 12 12 20 14 34 149 191 249 291
152 150 317 272 494 366 106 56 85 54 -45 -6z m847 -4077 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5225 9394 c-9 -9 -14 -23 -11 -30 3 -8 1 -14 -3 -14 -5 0 -12 6 -14
13 -4 9 -6 9 -6 0 -1 -7 -16 -27 -35 -45 -31 -29 -33 -33 -18 -46 16 -12 15
-13 -2 -8 -10 4 -25 1 -34 -6 -8 -7 -41 -13 -74 -13 -54 0 -62 -3 -88 -32 -16
-18 -32 -48 -35 -67 -9 -45 18 -102 58 -122 l30 -16 -7 -131 c-28 -573 -27
-561 -50 -581 -34 -30 -56 -69 -56 -101 0 -42 34 -94 74 -110 36 -15 22 18
253 -604 l35 -93 -22 -23 c-50 -53 -28 -145 42 -181 28 -14 39 -15 80 -4 l49
12 77 -53 c42 -29 138 -96 212 -148 74 -52 176 -123 225 -157 87 -59 90 -63
91 -100 1 -65 56 -124 114 -124 28 0 80 27 100 53 16 19 20 20 276 8 142 -7
317 -15 388 -18 l130 -6 36 -38 c46 -49 83 -58 129 -33 32 17 48 39 80 112 3
8 56 39 116 71 61 31 151 79 200 106 50 26 113 60 140 74 28 14 52 29 55 33 3
4 19 12 35 19 17 6 25 13 18 16 -6 2 -23 -3 -38 -13 -25 -16 -27 -16 -51 7
-13 13 -23 28 -20 35 8 21 -34 23 -92 4 -131 -43 -353 -110 -363 -110 -20 0
-5 19 99 124 93 93 102 107 92 125 -6 12 -16 21 -22 21 -6 0 3 14 21 30 17 17
31 34 31 39 0 5 6 11 14 14 8 3 20 16 26 29 7 13 -77 -65 -186 -173 -189 -187
-200 -196 -226 -187 -17 6 -30 20 -37 41 -5 18 -41 130 -80 250 l-71 219 25
32 c14 18 25 38 25 44 0 18 17 23 108 37 l87 14 -80 -3 c-44 -2 -64 -1 -45 1
45 6 64 21 49 39 -8 10 -3 14 23 17 18 2 -6 1 -53 -1 l-86 -5 -22 32 c-19 26
-21 37 -13 59 23 61 24 66 8 53 -8 -7 -20 -10 -26 -6 -5 3 -10 1 -10 -4 0 -6
-8 -11 -17 -10 -11 0 -13 3 -5 6 14 5 16 23 3 23 -5 0 -14 -13 -20 -30 -8 -25
-7 -30 5 -30 9 0 12 5 8 12 -4 7 -3 8 4 4 7 -4 12 -13 12 -21 0 -12 -6 -12
-37 -1 -22 8 -46 10 -58 5 -39 -15 -53 -8 -122 59 -98 94 -423 405 -516 494
-72 68 -77 76 -71 104 5 29 11 33 67 47 45 11 54 16 32 16 -23 1 -27 4 -15 11
13 8 12 10 -4 10 -12 0 -16 -5 -12 -15 3 -10 -1 -15 -14 -15 -13 0 -18 6 -15
20 2 11 -2 20 -8 21 -7 0 -3 4 8 9 15 7 10 8 -16 4 -32 -5 -37 -2 -63 34 -15
22 -29 56 -33 76 -6 40 -18 54 -27 31 -3 -8 -2 -15 3 -15 5 0 11 -10 14 -22 3
-13 8 -34 12 -48 3 -14 -3 -5 -13 19 -18 43 -20 44 -31 23 -18 -35 -61 -29
-100 13 -17 19 -29 35 -26 35 3 0 -14 27 -38 59 -23 33 -85 120 -137 195 -62
89 -101 136 -113 136 -14 0 -17 -5 -12 -20 3 -11 2 -20 -3 -20 -12 0 -29 20
-34 39 -2 8 -14 25 -28 37 -14 12 -22 26 -17 30 4 4 14 -1 22 -12 8 -10 15
-14 15 -7 0 6 -5 15 -11 18 -6 4 -8 14 -5 22 4 8 2 11 -5 7 -6 -4 -15 -1 -19
7 -5 8 -8 -1 -6 -22 2 -25 23 -64 67 -126 35 -50 84 -121 109 -158 25 -36 80
-116 123 -176 79 -112 85 -127 66 -146 -6 -6 -14 -26 -18 -43 -7 -37 -4 -36
-421 -144 -184 -48 -216 -54 -228 -42 -12 12 -4 47 61 248 42 128 100 310 130
403 47 144 58 170 76 173 12 2 19 7 16 12 -6 10 -48 0 -57 -14 -5 -7 -8 -7 -8
2 0 8 9 16 20 19 11 3 20 8 20 12 0 3 -32 37 -72 76 -64 63 -71 67 -64 40 4
-17 5 -27 1 -24 -3 4 -27 0 -53 -7 -43 -13 -48 -13 -59 3 -7 9 -13 22 -13 27
0 6 4 5 9 -3 8 -12 11 -12 23 0 39 41 87 113 81 122 -3 6 -10 1 -14 -11 -5
-12 -13 -22 -19 -22 -13 0 -5 46 11 66 16 19 4 18 -16 -2z m-5 -72 c0 -4 -10
-12 -22 -17 -13 -6 -22 -16 -20 -23 2 -13 -10 -16 -21 -5 -9 9 33 53 50 53 7
0 13 -3 13 -8z m-183 -88 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m236 -51 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m76 -37
c-2 -27 -2 -28 -6 -6 -3 14 -10 31 -15 38 -8 9 -7 11 6 6 10 -4 16 -17 15 -38z
m-72 18 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-73 -25 c-4
-7 0 -9 12 -6 59 18 70 18 88 -2 19 -21 17 -27 -90 -345 -59 -179 -110 -324
-112 -322 -1 2 -7 124 -13 270 l-10 267 36 40 c19 21 35 45 35 51 0 7 12 22
28 35 31 25 36 28 26 12z m134 -114 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7
10 -15 7 -18z m288 -89 c4 -11 15 -29 25 -40 11 -11 19 -24 19 -30 0 -6 -13 4
-30 22 -16 18 -30 40 -30 48 0 8 -5 14 -11 14 -5 0 -7 4 -4 10 3 5 -3 15 -15
22 -11 7 -20 20 -20 28 0 18 57 -46 66 -74z m-56 24 c0 -5 -2 -10 -4 -10 -3 0
-8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m55 -80 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m29 -35 c3 -8 1 -15 -3 -15 -5
0 -11 7 -15 15 -3 8 -1 15 3 15 5 0 11 -7 15 -15z m36 1 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m44 -69 c5 -21 4 -21 -9
-3 -8 10 -16 24 -18 30 -3 7 -9 7 -20 -3 -20 -16 -23 -6 -4 13 10 12 15 12 29
-1 9 -8 18 -24 22 -36z m-4 -60 c0 -16 -18 3 -23 25 -6 22 -5 22 8 4 8 -11 15
-24 15 -29z m28 -42 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z
m262 -175 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m31 -7 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-195
-185 l46 -33 -6 -70 c-4 -38 -11 -131 -16 -205 -38 -526 -48 -640 -59 -640 -7
0 -233 264 -376 438 -16 20 -85 102 -153 182 -104 123 -122 149 -117 170 4 14
8 26 10 28 1 2 87 25 191 52 104 27 236 63 294 79 58 16 113 30 122 30 10 1
38 -14 64 -31z m195 -29 c13 -11 68 -63 122 -115 58 -56 102 -92 109 -88 6 4
8 3 5 -3 -4 -6 30 -47 76 -91 45 -44 137 -133 205 -198 l123 -119 -12 -50 -13
-50 -75 -27 c-93 -32 -400 -143 -533 -192 -93 -34 -98 -35 -118 -18 -22 18
-22 19 -10 182 6 91 17 260 25 375 26 387 26 389 43 403 21 16 25 16 53 -9z
m-741 -451 c69 -83 139 -167 156 -187 124 -143 274 -328 274 -340 0 -10 -10
-12 -42 -8 -24 3 -94 11 -156 18 -107 12 -114 14 -127 39 -7 14 -30 35 -49 45
-20 11 -36 21 -36 23 0 1 -15 55 -34 120 -99 339 -126 432 -126 437 0 17 28
-12 140 -147z m1610 -87 c-13 -25 -13 -58 0 -64 6 -3 5 -4 -2 -3 -18 4 -23 67
-6 77 19 12 20 11 8 -10z m-53 -37 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m176 -89 c-1 -5 -10 -11 -20 -12 -15 -2 -15 -1 2 6 19 8 19 9 -5
14 l-25 5 25 -1 c14 -1 24 -6 23 -12z m-309 -150 c18 -14 48 -26 67 -28 33 -4
35 -6 65 -98 33 -99 67 -205 104 -328 20 -65 21 -75 7 -88 -13 -14 -28 -11
-139 25 -115 38 -785 265 -818 278 -21 8 -10 13 165 78 83 30 229 84 325 120
96 36 179 65 183 66 5 0 23 -11 41 -25z m-1029 -260 c11 -2 28 -20 39 -39 20
-38 68 -66 113 -66 26 0 33 -9 82 -105 30 -58 52 -108 49 -110 -3 -3 -57 32
-121 77 -64 46 -171 121 -237 167 -66 46 -120 87 -120 90 0 6 147 -5 195 -14z
m1705 -19 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z m-1005
-180 c237 -80 429 -148 428 -153 -1 -4 -103 -18 -225 -30 -123 -12 -257 -25
-299 -30 l-77 -9 -37 33 c-21 18 -48 33 -61 33 -19 0 -35 24 -98 146 -42 80
-76 147 -76 150 0 8 23 1 445 -140z m1195 4 c0 -5 -8 -10 -17 -10 -15 0 -16 2
-3 10 19 12 20 12 20 0z m-130 -107 c0 -9 -250 -134 -266 -133 -17 0 -26 31
-14 46 6 7 50 24 98 39 48 14 103 33 122 40 40 16 60 18 60 8z m-516 -149 c4
-9 5 -18 4 -20 -2 -1 -47 0 -101 3 -53 3 -96 8 -93 10 4 4 115 20 157 22 17 1
29 -5 33 -15z"/>
<path d="M5300 9305 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"/>
<path d="M5473 9185 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M5491 9164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5522 9055 c-7 -15 -8 -27 -2 -30 6 -4 10 3 10 14 0 23 16 28 23 9 2
-7 7 -10 11 -7 3 3 -2 13 -12 22 -18 16 -19 16 -30 -8z"/>
<path d="M6052 8640 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6030 8594 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M6220 8520 c-20 -6 -21 -8 -5 -8 11 0 31 4 45 8 20 6 21 8 5 8 -11 0
-31 -4 -45 -8z"/>
<path d="M6893 7833 c-14 -29 -8 -47 7 -23 9 13 14 50 8 50 -2 0 -9 -12 -15
-27z"/>
<path d="M7208 7653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7178 7633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M9417 7565 c-37 -19 -49 -32 -78 -85 -57 -104 -73 -178 -43 -211 32
-36 3 -37 -66 -3 -39 19 -73 34 -76 34 -2 0 -37 16 -76 35 -39 20 -72 34 -73
33 0 -2 -16 -36 -34 -77 -27 -60 -31 -75 -20 -82 8 -5 17 -9 21 -9 6 0 40 -15
193 -85 28 -12 70 -32 95 -43 25 -11 81 -37 125 -57 134 -62 318 -145 322
-145 1 0 18 33 36 72 l34 73 -101 48 c-55 27 -121 57 -146 67 -95 38 -169 80
-175 100 -8 27 19 120 46 153 27 35 52 34 136 -4 37 -17 82 -37 98 -44 17 -7
69 -31 116 -53 106 -49 129 -57 129 -43 0 6 13 39 30 74 l30 62 -68 32 c-261
124 -378 173 -410 173 -9 0 -29 -7 -45 -15z m60 -11 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-390 -260 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z"/>
<path d="M3890 7417 c-3 -3 -50 -10 -105 -16 -55 -6 -176 -20 -270 -31 -93
-12 -210 -25 -258 -30 -49 -5 -92 -11 -95 -15 -3 -3 11 -40 32 -81 37 -72 39
-74 69 -70 121 20 188 28 191 25 2 -2 33 -62 69 -134 l65 -130 -66 -68 c-37
-38 -72 -74 -78 -80 -9 -9 -1 -32 30 -90 l41 -78 59 63 c390 413 480 509 494
530 13 18 12 24 -6 57 -11 20 -33 61 -49 91 -27 53 -29 55 -73 58 -25 2 -48 1
-50 -1z m27 -23 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-17
-131 c0 -5 -51 -62 -114 -127 l-114 -119 -43 84 c-24 46 -47 92 -51 101 -8 20
-15 18 152 43 63 9 122 18 130 20 29 6 40 5 40 -2z m-26 -238 c-4 -8 -11 -15
-16 -15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z"/>
<path d="M7510 7280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7523 7248 c-31 -31 -59 -34 -33 -3 8 10 9 15 1 15 -6 0 -11 -4 -11
-10 0 -5 -7 -10 -15 -10 -23 0 -35 -32 -20 -54 12 -16 16 -16 27 -4 7 8 31 33
53 56 46 49 44 57 -2 10z m-53 -43 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0
8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M7758 7083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7708 7063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7740 7056 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M9006 6870 c-127 -134 -181 -244 -156 -319 15 -45 35 -69 110 -132
98 -81 179 -137 211 -144 58 -13 119 13 185 79 60 61 159 198 166 229 2 10
-12 30 -32 47 -34 29 -57 40 -46 21 4 -5 3 -11 -1 -13 -5 -1 -19 -25 -33 -51
-14 -26 -44 -70 -68 -97 -75 -86 -124 -77 -262 48 -14 13 -37 30 -52 39 -76
46 -60 114 52 225 l79 78 -37 30 c-20 16 -39 30 -43 30 -3 0 -36 -32 -73 -70z
m169 -570 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z"/>
<path d="M4110 6708 c-38 -34 -340 -321 -481 -458 l-88 -85 56 -58 56 -58 41
38 c22 21 73 70 113 109 69 69 90 76 72 26 -10 -26 2 -50 68 -128 50 -59 105
-100 154 -114 66 -19 114 9 282 170 146 139 151 182 39 317 -58 69 -120 114
-162 118 -40 3 -48 12 -25 29 18 13 17 16 -28 62 -26 27 -47 52 -47 57 0 14
-15 6 -50 -25z m127 -191 c36 -20 103 -103 109 -137 6 -30 1 -37 -106 -140
-126 -122 -152 -134 -201 -100 -36 27 -109 116 -109 135 0 30 27 64 127 158
109 101 131 112 180 84z m113 -371 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4
13 16 21 21 21 13z"/>
<path d="M8550 6330 c-102 -32 -245 -163 -273 -248 -14 -40 -15 -56 -5 -83 21
-63 103 -173 121 -162 6 3 7 1 3 -6 -10 -15 75 -116 114 -136 32 -16 91 -20
118 -7 108 53 136 67 146 76 6 6 31 27 56 46 25 19 67 58 94 87 47 50 48 54
32 71 -9 9 -23 26 -31 37 -15 19 -16 19 -46 -17 -35 -43 -139 -128 -186 -152
-50 -25 -78 -20 -118 21 -19 20 -35 41 -34 47 0 10 36 39 237 197 28 22 52 45
52 49 0 15 -18 21 -24 8 -3 -7 -4 -4 -1 7 6 24 -90 137 -136 160 -30 16 -79
18 -119 5z m-23 -106 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m98 -62 c36 -42 37 -46 21 -56 -10 -6 -52 -37 -93 -70 -40 -32 -76 -57 -78
-55 -33 34 -65 87 -65 109 0 47 114 135 160 123 9 -3 34 -25 55 -51z m-235 34
c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"/>
<path d="M4728 6096 c-42 -15 -79 -55 -122 -130 -16 -28 -34 -58 -41 -66 -21
-25 -61 -103 -69 -133 -23 -95 97 -224 294 -315 103 -48 103 -48 128 -3 26 45
27 51 9 51 -13 0 -60 20 -146 63 -24 12 -63 40 -86 63 -41 39 -42 43 -35 82 6
36 33 92 44 92 3 0 41 -22 86 -50 45 -27 83 -50 85 -50 2 0 23 -13 47 -29 24
-16 60 -38 80 -49 l37 -21 6 20 c4 12 25 50 48 86 53 85 60 144 24 205 -37 63
-133 138 -158 122 -8 -4 -9 -3 -5 4 8 14 -41 40 -109 58 -61 17 -70 17 -117 0z
m197 -140 c86 -60 92 -79 47 -150 l-28 -44 -50 28 c-75 41 -131 78 -139 90 -4
6 0 19 9 29 9 10 16 23 16 29 0 6 12 20 27 32 31 24 70 20 118 -14z"/>
<path d="M7765 6038 c-33 -17 -62 -32 -64 -35 -3 -2 9 -33 27 -69 18 -35 32
-69 32 -74 0 -5 -22 -22 -50 -38 l-49 -28 20 -41 20 -41 52 23 c37 18 54 21
64 13 7 -6 13 -15 13 -20 0 -15 127 -265 152 -298 46 -63 129 -69 241 -16 104
49 100 45 77 94 l-20 42 -56 -27 c-80 -40 -92 -31 -181 142 -40 77 -71 149
-70 160 1 13 21 29 62 48 33 16 61 30 63 32 6 5 -9 50 -23 69 -14 18 -16 18
-77 -13 -35 -17 -69 -31 -75 -31 -6 0 -26 32 -44 70 -17 39 -37 70 -43 70 -6
-1 -38 -15 -71 -32z m338 -645 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24
-2 18 -5z"/>
<path d="M6175 5636 c-4 -96 -5 -182 -2 -191 4 -8 2 -15 -3 -15 -6 0 -10 -8
-10 -19 0 -22 55 -31 200 -31 135 0 140 -4 140 -125 0 -92 -1 -95 -27 -112
-33 -22 -136 -22 -255 0 l-78 13 0 -66 0 -67 63 -7 c177 -18 266 -19 331 -1
107 29 132 71 143 241 5 91 4 106 -16 146 -31 65 -85 87 -230 97 l-94 6 2 80
c1 44 5 83 9 86 4 4 75 3 157 -1 83 -4 153 -5 158 -3 4 2 7 30 7 62 l0 58 -52
6 c-29 3 -133 6 -231 6 -97 1 -183 4 -191 7 -11 4 -15 -27 -21 -170z m482
-333 c-2 -16 -4 -3 -4 27 0 30 2 43 4 28 2 -16 2 -40 0 -55z"/>
<path d="M5382 5789 c3 -8 18 -52 34 -99 16 -47 32 -86 37 -88 4 -2 7 -10 7
-17 0 -7 9 -35 21 -62 23 -54 26 -46 -47 -98 -15 -11 -46 -34 -68 -50 -23 -17
-63 -46 -91 -65 -84 -57 -84 -55 6 -84 84 -27 109 -31 109 -18 0 4 12 15 28
24 15 10 50 37 77 62 56 51 61 53 70 29 3 -10 20 -60 38 -112 l32 -94 85 -23
c46 -13 86 -22 87 -20 2 2 -1 14 -6 27 -6 13 -31 78 -56 144 -25 66 -50 130
-56 143 -5 13 -7 27 -4 32 3 4 37 30 77 56 40 27 75 52 78 55 3 3 32 25 64 49
67 49 71 43 -47 80 l-68 21 -57 -46 c-31 -26 -65 -53 -75 -61 -33 -27 -43 -16
-72 80 l-28 95 -71 21 c-39 12 -80 24 -90 27 -13 4 -17 1 -14 -8z"/>
<path d="M7470 5728 c-19 -5 -52 -14 -72 -19 -36 -9 -51 -33 -27 -41 5 -2 7
-11 4 -20 -6 -15 -10 -14 -33 8 -24 23 -33 25 -92 21 -129 -9 -231 -50 -262
-107 -14 -25 -18 -49 -15 -85 5 -74 67 -323 89 -361 32 -54 100 -75 187 -58
17 4 28 10 26 15 -3 4 0 11 6 15 8 5 9 3 4 -6 -6 -10 0 -12 27 -8 62 11 107
36 129 72 27 47 44 51 46 12 1 -17 5 -57 8 -88 9 -67 -5 -97 -56 -123 -37 -20
-191 -54 -241 -55 -20 0 -39 -3 -42 -8 -2 -4 1 -29 7 -56 l11 -48 45 7 c140
21 291 70 358 116 91 63 105 119 69 275 -37 159 -56 237 -60 254 -7 26 -66
290 -66 295 0 6 -7 5 -50 -7z m-33 -34 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-53 -133 c7 -14 21 -64 31 -111 9 -47 22 -103 28 -125 20 -76
4 -104 -73 -129 -67 -22 -146 -21 -159 2 -18 33 -71 265 -71 309 0 41 3 45 34
58 19 8 53 17 75 21 23 4 44 10 46 14 10 16 76 -14 89 -39z m-261 -461 c9 -11
13 -20 9 -20 -4 0 -15 9 -25 20 -9 11 -13 20 -9 20 4 0 15 -9 25 -20z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
